'use client'

import React, { useState } from 'react'
import { HeroUIProvider } from '@heroui/system'
import { useRouter } from 'next/navigation'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import ModalContext from '@/app/context'

import { ThemeProvider } from '@/components/theme-provider'

const queryClient = new QueryClient()

export function Providers({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const [openLogin, setOpenLogin] = useState(false)
  const [isLogin, setIsLogin] = useState(true)
  const value = { openLogin, setOpenLogin, isLogin, setIsLogin }
  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="dark"
      enableSystem
      disableTransitionOnChange
    >
      <ModalContext.Provider value={value}>
        <QueryClientProvider client={queryClient}>
          <HeroUIProvider navigate={router.push}>{children}</HeroUIProvider>
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        </QueryClientProvider>
      </ModalContext.Provider>
    </ThemeProvider>
  )
}
